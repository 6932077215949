import { boardAccess, boardSettings, duplicate, slideShow } from '../constants/Paths.js';
import type { MenuItem } from '../types/MenuItemType';

export const getMainMenuItems = ({
  boardScrollFull,
  board,
  project,
  isLoggedIn,
  user,
  canEdit,
  isMobile,
  onLogout,
  onAuthenticateUsingOidc,
  wrappedOpenAbout,
  wrappedOpenTrashPage,
  handleBookmarkClick,
  toggleScrollMode,
  showScrollChange,
  handleDelete,
  canDelete,
  isPasswordProtected,
}: {
  boardScrollFull: boolean;
  board: any;
  project: any;
  isLoggedIn: boolean;
  isMobile: boolean;
  canDelete: boolean;
  user: any;
  canEdit: boolean;
  onLogout: () => void;
  onAuthenticateUsingOidc: () => void;
  wrappedOpenAbout: () => void;
  wrappedOpenTrashPage: () => void;
  handleBookmarkClick: () => void;
  toggleScrollMode: () => void;
  handleDelete: () => void;
  showScrollChange: boolean;
  isPasswordProtected: boolean;
}): readonly MenuItem[] => {
  const items: MenuItem[] = [];
  if (board && isMobile && showScrollChange) {
    items.push({
      type: 'button',
      translationKey: 'common.showScrollChange',
      onClick: toggleScrollMode,
      icon: boardScrollFull
        ? '/assets/images/icon-scroll-all.svg'
        : '/assets/images/icon-scroll-in-column.svg',
    });
  }
  if (project && board && isMobile) {
    items.push({
      type: 'link',
      url: slideShow(project.name, board.id),
      translationKey: 'common.present',
      icon: '/assets/images/icon-present.svg',
    });
  }
  if (board && user && (canEdit || !isPasswordProtected) && isMobile) {
    items.push({
      type: 'link',
      url: duplicate(board.id),
      translationKey: 'common.duplicateBoard',
      icon: '/assets/images/icon-duplicate.svg',
    });
  }
  if (project && board && user && isMobile) {
    items.push({
      type: 'button',
      translationKey: project.favorite ? 'common.dontBookmarkBoard' : 'common.bookmarkBoard',
      icon: project.favorite
        ? '/assets/images/icon-bookmark-active.svg'
        : '/assets/images/icon-bookmark.svg',
      onClick: handleBookmarkClick,
    });
  }

  if (board && user && canEdit && isMobile) {
    items.push({
      type: 'link',
      translationKey: 'common.changePermission',
      url: boardAccess(board.id),
      icon: '/assets/images/icon-lock.svg',
    });
  }
  if (isLoggedIn) {
    items.push({
      type: 'button',
      translationKey: 'common.trash',
      onClick: wrappedOpenTrashPage,
      icon: '/assets/images/icon-trash.svg',
    });
  }
  if (board && user && canEdit && isMobile) {
    items.push({
      type: 'link',
      translationKey: 'common.settings',
      url: boardSettings(board.id),
      icon: '/assets/images/icon-settings.svg',
    });
  }
  if (board && user && canEdit && isMobile && canDelete) {
    items.push({
      type: 'button',
      translationKey: 'common.delete',
      onClick: handleDelete,
      icon: '/assets/images/icon-trash.svg',
    });
  }
  if (items.length > 0) {
    items.push({
      type: 'separator',
    });
  }
  items.push({
    type: 'button',
    translationKey: 'common.aboutMikado',
    onClick: wrappedOpenAbout,
    icon: '/assets/images/icon-info.svg',
  });

  items.push({
    type: 'separator',
  });
  if (isLoggedIn) {
    items.push({
      type: 'small-text',
      text: user.email,
    });
    items.push({
      type: 'button',
      onClick: onLogout,
      translationKey: 'common.logOutTitle',
      icon: '/assets/images/icon-logout.svg',
    });
  } else {
    items.push({
      type: 'button',
      onClick: onAuthenticateUsingOidc,
      translationKey: 'common.login',
      icon: '/assets/images/icon-login.svg',
    });
  }
  return items;
};
