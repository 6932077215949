import React from 'react';
import { useTranslation } from 'react-i18next';
import { isProduction } from '../../constants/Flags';
import { ConnectionError } from '../../errors/ConnectionError.js';
import ErrorComponent from '../ErrorComponent/ErrorComponent.js';

const getErrorString = (error) => {
  if (error && error.message && error.stack) {
    return `${error.stack}`;
  }
  if (error && error instanceof Error) {
    return error.toString();
  }
  if (error && error.code) {
    return `Code: ${error.code}`;
  }
  return `Unknown Error: ${error}`;
};

const getHeading = (t, error) => {
  const isConnectionError = error && error instanceof ConnectionError;
  if (isConnectionError) {
    return t('common.connectionError');
  }
  return t('common.oopsError');
};

function GlobalError({ globalError }: { globalError: unknown }) {
  const [t] = useTranslation();
  const showErrorLog = !isProduction();
  const errorString = showErrorLog ? getErrorString(globalError) : undefined;
  const heading = getHeading(t, globalError);
  return (
    <div className="text-black flex justify-center items-center flex-col h-full">
      <ErrorComponent
        heading={heading}
        subheading={errorString}
        image=""
        showDisableWebSocketMessage
      />
    </div>
  );
}

export default React.memo(GlobalError);
