import { kebabCase } from 'lodash-es';
import { call, put, select, take } from 'redux-saga/effects';
import actions from '../../../actions';
import api from '../../../api';
import ActionTypes from '../../../constants/ActionTypes';
import * as Paths from '../../../constants/Paths';
import * as entryActions from '../../../entry-actions/index.js';
import { push } from '../../../lib/redux-router';
import * as selectors from '../../../selectors';
import * as NavigationStorage from '../../../utils/navigationStorage';
import { getPlainTextPassword } from '../../../utils/plainTextPassword';
import request from '../request';

export function* goToRoot() {
  yield put(push(Paths.ROOT));
}

export function* goToProject(projectId) {
  yield put(push(Paths.PROJECTS.replace(':id', projectId)));
}

export function* goToBoard(boardId: string, projectName: string) {
  yield put(push(Paths.BOARDS.replace(':id', `${kebabCase(projectName)}-${boardId}`)));
}

export function* goToCard(cardId) {
  yield put(push(Paths.CARDS.replace(':id', cardId)));
}

export function* goToSlideshow(boardId: string, projectName: string, slideNumber = 1) {
  yield put(
    push(
      Paths.SLIDESHOW.replace(':id', `${kebabCase(projectName)}-${boardId}`).replace(
        ':slideNumber?',
        `${slideNumber}`,
      ),
    ),
  );
}

export function* handleLocationChange() {
  const pathsMatch = yield select(selectors.selectPathsMatch);
  const location = yield select(selectors.selectLocation);
  const searchMatch = yield select(selectors.selectSearch);
  if (!pathsMatch) {
    return;
  }

  switch (pathsMatch.pattern.path) {
    case Paths.OIDC_CALLBACK:
      yield call(goToRoot);

      return;
    default:
  }

  const isInitializing = yield select(selectors.selectIsInitializing);

  if (isInitializing) {
    yield take(ActionTypes.CORE_INITIALIZE);
  }

  let board;
  let users;
  let projects;
  let boardMemberships;
  let labels;
  let lists;
  let cards;
  let cardMemberships;
  let cardLabels;
  let tasks;
  let attachments;
  let deletedNotifications;
  let projectProfessions;

  if (pathsMatch.pattern.path === Paths.TRASH) {
    yield put(entryActions.getScheduledToBeDeletedProjects());
    return;
  }

  if (pathsMatch.pattern.path === Paths.ROOT && searchMatch.search) {
    const parsed = NavigationStorage.getState(location);
    if (parsed && parsed.committedSearchValue) {
      yield put(actions.searchRestore(parsed.searchForm));
      return;
    }
    const committedSearchValue = new URLSearchParams(searchMatch.search).get('search') || '';
    yield put(entryActions.search(committedSearchValue));
    return;
  }

  switch (pathsMatch.pattern.path) {
    case Paths.BOARDS:
    case Paths.SLIDESHOW:
    case Paths.CARDS: {
      const currentBoard = yield select(selectors.selectCurrentBoard);
      let boardId = currentBoard?.id;
      const isBoardAlreadyLoaded = currentBoard && currentBoard.isFetching === false;
      if (Paths.needsBoard(pathsMatch.pattern.path)) {
        boardId = pathsMatch.params.id;
        if (!Paths.needsBoardRefresh(pathsMatch.pattern.path) && isBoardAlreadyLoaded) {
          break;
        }
      } else if (Paths.needsCard(pathsMatch.pattern.path)) {
        const cardId = pathsMatch.params.id;
        const plainCard = yield select(selectors.selectPlainCard, cardId);
        if (!plainCard) {
          yield put(actions.handleLocationChange.fetchCard(cardId));

          try {
            ({
              item: { boardId },
            } = yield call(request, api.getCard, cardId));
          } catch (error) {
            yield put(actions.handleLocationChange.fetchCardFailure(cardId));
            throw error;
          }
        }
      }
      if (!boardId) {
        // this either means that
        // 1. the board wasn't loaded yet --> try again
        // 2. the board was not found --> 404
        // 3. the user doesn't have access to this board --> 404
        // We will just try to fetch the board (again) in either case
        boardId = pathsMatch.params.id;
      }

      if (!isBoardAlreadyLoaded && boardId) {
        yield put(actions.handleLocationChange.fetchBoard(boardId));

        const plainTextPassword = getPlainTextPassword(boardId);

        try {
          ({
            item: board,
            included: {
              users,
              projects,
              boardMemberships,
              labels,
              lists,
              cards,
              cardMemberships,
              cardLabels,
              tasks,
              attachments,
              projectProfessions,
            },
          } = yield call(request, api.getBoard, boardId, true, plainTextPassword));
        } catch (error) {
          yield put(actions.fetchBoard.failure(boardId, error));
        }
      }

      break;
    }
    default:
  }

  yield put(
    actions.handleLocationChange(
      board,
      users,
      projects,
      boardMemberships,
      labels,
      lists,
      cards,
      cardMemberships,
      cardLabels,
      tasks,
      attachments,
      deletedNotifications,
      projectProfessions,
    ),
  );
}
