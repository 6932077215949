import { useTranslation } from 'react-i18next';
import ButtonType from '../../constants/ButtonType';
import Button from '../Button/Button';

function ErrorComponent({
  heading,
  subheading,
  image,
  showDisableWebSocketMessage,
}: {
  heading?: string;
  subheading?: string;
  image?: string;
  showDisableWebSocketMessage?: boolean;
} = {}) {
  const [t] = useTranslation();
  const actualHeading = heading ?? t('common.pageNotFound');
  const actualSubHeading = subheading ?? t('common.pageNotFoundPleaseContactSupportThanks');
  const actualImage = image ?? '/assets/images/not-found.svg';
  return (
    <div className="w-full h-full bg-bgGray p-4 flex items-center justify-center">
      <div className="w-full md:w-2/3 flex flex-col items-center gap-8">
        {actualImage && <img src={actualImage} alt="" className="object-fill" />}
        <div className="flex flex-col gap-2 items-center">
          <h1 className="text-blue100 font-bold text-base leading-5 lg:text-2xl text-center max-h-[100px] overflow-y-auto">
            {actualHeading}
          </h1>
          <p className="w-11/12 lg:w-3/4 text-blue50 font-normal text-sm leading-4 lg:text-base lg:leading-5 text-center max-h-[300px] overflow-y-auto overflow-x-hidden">
            {actualSubHeading}
          </p>
        </div>
        <a className="w-full md:max-w-[200px]" href="/">
          <Button className="w-full" variant={ButtonType.Green}>
            {t('common.homePage')}
          </Button>
        </a>
        {showDisableWebSocketMessage && (
          <a className="w-full md:max-w-[200px]" href="/?DISABLE_WEB_SOCKETS=true">
            <Button className="w-full" variant={ButtonType.Green}>
              {t('common.disableWebsockets')}
            </Button>
          </a>
        )}
      </div>
    </div>
  );
}

export default ErrorComponent;
